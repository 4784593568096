
body,
html {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.h-100 {
  height: 100%;
}

.centered-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

a {
  text-decoration: none;
}

.ck-editor__editable_inline {
  min-height: 400px;
}

@keyframes line-top-in {
  0% {
    left: -5px;
    bot: 0;
    transform: rotate(-45deg);
  }
  100% {
    left: 0;
    bot: 1px;
    transform: rotate(0deg);
  }
}

@keyframes line-top-out {
  0% {
    left: 0;
    top: 0;
    transform: rotate(0deg);
  }
  100% {
    left: -5px;
    top: 1px;
    transform: rotate(-45deg);
  }
}

@keyframes line-bot-in {
  0% {
    left: -5px;
    transform: rotate(45deg);
  }
  100% {
    left: 0;
    transform: rotate(0deg);
  }
}

@keyframes line-bot-out {
  0% {
    left: 0;
    transform: rotate(0deg);
  }
  100% {
    left: -5px;
    transform: rotate(45deg);
  }
}

h1,
h2,
h3,
h4 {
  letter-spacing: 0.25px !important;
  color: #213754 !important;
}

h1 {
  border-bottom: 1px solid #213745 !important;
  padding-bottom: 10px !important;
}

.image-container {
  display: flex;
  align-items: center;
  font-weight: 700;
}

.thumbnail {
  width: 50px; /* Adjust the size as needed */
  height: 50px; /* Adjust the size as needed */
  margin-right: 10px; /* Space between image and text */
  object-fit: cover;
}

.time-status-badge {
  width: fit-content;
  height: 24px;
  padding: 2px 10px 2px 10px;
  border-radius: 24px;
  background: #F0FDF4;
  color: #14C44F;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.69;
  text-align: center;
}

.is-leaderboard {
  width: 100%;
  background: #ffd910;
  border: 1px solid rgba(255, 255, 255, 0.38);
}

.time-status-badge.inactive {
  background: rgb(255 239 239);
}
